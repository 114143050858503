import React from 'react';
import logo from '../../logos.jpg';
import '../../App.css';

const Home =() => {
    return (
        <div className="App">
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
        </header>
        </div>        
    );
};

export default Home;