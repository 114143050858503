import { Navigate } from "react-router-dom";

type ProtectedType = {
  isLoggedIn: boolean,
  children: any,
}

const Protected = ({ isLoggedIn, children }: ProtectedType) => {
  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }
  
  return children;
};

export default Protected;