import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../../common/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function Auth() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect( () => {
        if (loading) {
            return;
        }

        if (user) navigate("/dashboard");
    }, [user, loading]);

    console.log(process.env.REACT_APP_FIREBASE_APP_ID);

    return (
        <>
            <input type="text" 
                placeholder="E-mail Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <input type="text" 
                placeholder="Password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
            />
            <button onClick={() => logInWithEmailAndPassword(email, password)}> Login </button>

            <button className="login__btn login__google" onClick={signInWithGoogle}> Login with Google </button>
        </>
    );
}


export default Auth;