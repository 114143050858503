import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState } from "react";
import ProtectedRoute from "./common/Protected";
import './App.css';
import Home from './pages/Home/Home';
import Dashboard from './pages/Dashboard';
import Auth from './pages/Auth';

function App() {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const logIn = () => {
    setisLoggedIn(true);
  };
  const logOut = () => {
    setisLoggedIn(false);
  };  

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth" element={<Auth />} />
        
        <Route path="/dashboard" 
          element={ 
            <ProtectedRoute isLoggedIn={isLoggedIn}> 
              <Dashboard /> 
            </ProtectedRoute> 
          } 
        />

      </Routes>
    </Router>
  );
}

export default App;
